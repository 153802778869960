export function Log(data,includeTime=true){
	const today = new Date();
	const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
	return{
		type:"Log",
		payload:includeTime?`[${time}] ${data}`:data,
	}
}
export function Login(login,token=null,usertype=null){
	return{
		type:"Login",
		payload:login,
		token:token,
		usertype:usertype
	}
}
export function setpageindex(index,payload=null){
	if(index===2||index===4||index===6||index===9||index===6.1){
		return{
			type:"setformdefinition",
			payload:payload,
			index:index
		}
	}
	if(index===11||index===11.1){
		return{
			type:"setforms",
			payload:payload,
			index:index
		}
	}
	return{
		type:"setpageindex",
		payload:index
	}
}
export function setprojectlist(list,project_dict={}){
	return{
		type:"setprojectlist",
		payload:list,
		project_dict:project_dict,
	}
}
export function setcategory(cat){
	return{
		type:"setcategory",
		payload:cat
	}
}
export function setstamp(stamp){
	return{
		type:"setstamp",
		payload:stamp
	}
}
export function setmenu(onoff){
	return{
		type:"setmenu",
		payload:onoff
	}
}


