export default function reducer(state={
	isloading:false,
	isLogin:false,
	version:'0.4.1',
	server_ip:'',
	//server_ip:'http://localhost:3001',
	//server_ip:'https://oat-mgi-01.robot-data.ai',
	token:null,
	usertype:null,
	page_index:0, 
	category:'OT',
	form_definition:null,
	project_list:[],
	project_dict:{},
	//stamp:null,
	forms:[],
	ismenu:true
},action) {
	//const newState = {...state}
	switch(action.type){
		case "Loading":{
			state={...state, 
				isloading:true
			}
			break;
		}
		case "Login":{
			state={...state, 
				isLogin:action.payload,
				token:action.token,
				usertype:action.usertype,
				stamp:null
			}
			break;
		}
		case 'setstamp':{
			state={...state,
				stamp:action.payload
			}
			break
		}
		case "setprojectlist":{
			state={...state,
				project_list:action.payload,
				project_dict:action.project_dict,
			}
			break
		}
		case "setcategory":{
			state={...state, 
				category:action.payload,
			}
			break;
		}
		case "setpageindex":{
			state={...state, 
				page_index:action.payload,
			}
			break;
		}
		case 'setformdefinition':{
			state={...state, 
				page_index:action.index,
				form_definition:action.payload
			}
			break;
		}
		case 'setforms':{
			state={...state, 
				page_index:action.index,
				forms:action.payload
			}
			break;
		}
		case 'setmenu':{
			state={...state, 
				ismenu:action.payload
			}
			break;
		}
		case "ERR":{
			throw new Error("AHHHH")
		}
		default: {
			;
		}
	}

	return state
}